<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null && permission.read_perm == 1">
      <div v-if="getLoginPayroll">
        <div class="fluid" id="additionalList">
          <div>
            <div class="additional-list-div">
              <v-card elevation="0" class="px-3 py-2" style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h6
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      KOMPONEN TAMBAHAN PAYROLL
                    </h6>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="additional-list-toolbar-1"
                        flat
                        style="height: 60px; border-radius: 10px 10px 0 0"
                      >
                        <!-- <router-link to="/hr/permission/submission"> -->
                        <v-btn
                          v-if="permission.create_perm == 1"
                          outlined
                          elevation="1"
                          color="indigo"
                          class="indigo--text font-weight-bold"
                          style="font-size: 12px"
                          @click="createAdditional"
                        >
                          Buat baru
                        </v-btn>
                        <!-- </router-link> -->
                      </v-toolbar>
                      <v-toolbar
                        class="additional-list-toolbar-1"
                        flat
                        style="height: 60px; border-radius: 10px 10px 0 0"
                      >
                        <div class="additional-list-toolbar-1">
                          <div
                            style="width: 200px; margin-top: 15px; height: 40px"
                          >
                            <v-text-field
                              class="attendance-start-date mr-2"
                              dense
                              label="Periode"
                              type="month"
                              name="monthperiod"
                              step="1"
                              v-model="paramAPI.start_date"
                              outlined
                              clearable
                            />
                          </div>
                          <div
                            style="width: 200px; margin-top: 15px; height: 40px"
                            class="mr-2"
                          >
                            <v-select
                              label="Company"
                              v-model="paramAPI.company_id"
                              :items="dropdown.company"
                              style="position: relative; font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              clearable
                              outlined
                              :disabled="isDisableCompanyDropdown"
                              @change="companyWatcher"
                            />
                          </div>
                          <div
                            style="width: 200px; margin-top: 15px; height: 40px"
                            class="mr-2"
                          >
                            <v-select
                              label="Department"
                              v-model="paramAPI.department_id"
                              :items="dropdown.department"
                              style="position: relative; font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              clearable
                              outlined
                              @change="departmentWatcher"
                            />
                          </div>
                        </div>
                        <div
                          class="additional-list-toolbar-1"
                          style="margin-left: auto"
                        >
                          <div
                            style="
                              width: 260px;
                              margin-left: auto;
                              margin-top: 15px;
                              height: 40px;
                            "
                          >
                            <v-text-field
                              v-model="paramAPI.keyword"
                              label="cari disini"
                              type="search"
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              @keyup.enter="searchEnter"
                              :disabled="loading"
                            />
                          </div>
                        </div>
                      </v-toolbar>
                    </div>

                    <v-toolbar
                      class="additional-list-toolbar-2"
                      flat
                      style="
                        height: 60px;
                        border-radius: 10px 10px 0 0;
                        border-bottom: 1px solid #e0e0e0;
                      "
                    >
                      <div>
                        <v-text-field
                          class="attendance-start-date mr-2"
                          dense
                          label="Periode"
                          type="month"
                          name="monthperiod"
                          style="height: 30px"
                          step="1"
                          v-model="paramAPI.start_date"
                          outlined
                          clearable
                        />
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      class="additional-list-toolbar-2"
                      flat
                      style="
                        height: 60px;
                        border-radius: 10px 10px 0 0;
                        border-bottom: 1px solid #e0e0e0;
                      "
                    >
                      <v-text-field
                        v-model="paramAPI.keyword"
                        label="Cari disini"
                        type="search"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        @keyup.enter="searchEnter"
                        :disabled="loading"
                        style="position: relative; top: 15px; width: 10px"
                      ></v-text-field>
                    </v-toolbar>
                  </v-col>

                  <v-col cols="12" style="width: 100%">
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClick"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      :page="paramAPI.page"
                      @update:page="updatePage"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.name }}
                        </div>
                      </template>
                      <template
                        v-slot:[`item.payroll_component_name`]="{ item }"
                      >
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.payroll_component_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.company_name`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.company_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.template_name`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.template_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.department_name`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.department_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip left v-if="permission.update_perm == 1">
                          <template v-slot:activator="{ on, attrs }"
                            ><v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="rowClick(item)"
                              small
                              color="primary"
                              class="mr-2"
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip right v-if="permission.delete_perm == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="error"
                              @click.stop="deleteItem(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.period`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            width: 120px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{
                            `${item.period_start_month}/${item.period_start_year} s/d ${item.period_end_month}/${item.period_end_year}`
                          }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
        style="height: 80vh"
      >
        <v-card
          v-if="showOtpInput"
          elevation="5"
          class="ma-auto position-relative"
          style="max-width: 500px; background-color: rgba(240, 240, 240, 0.7)"
        >
          <v-card-text
            style="padding: 15px 15px 0 15px; margin: 0"
            class="d-flex justify-center align-center"
          >
            <v-row style="padding: 10px 40px">
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                    >
                      MASUKKAN KODE OTP
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-otp-input
                      v-model="otp"
                      :disabled="loading || timer === null"
                      length="6"
                    ></v-otp-input>
                    <v-overlay absolute :value="loading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-col>
                  <v-col
                    v-if="this.timer !== null"
                    cols="12"
                    style="padding: 0"
                  >
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.6);`"
                    >
                      {{ this.timer }}
                    </p>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <div
            class="d-flex justify-center align-center"
            style="padding: 0 50px"
          >
            <v-btn
              v-if="this.timer !== null"
              @click="verifyOTP"
              :loading="loading"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Verify
            </v-btn>
            <v-btn
              v-else
              @click="generateOTP"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Gnerate Ulang
            </v-btn>
          </div>
        </v-card>
        <v-form
          v-else
          ref="loginForm"
          @submit.prevent="loginPayroll"
          style="position: relative; width: 500px"
          :disabled="loading"
        >
          <v-card
            elevation="5"
            style="
              padding-top: 20px;
              background-color: rgba(240, 240, 240, 0.7);
            "
          >
            <v-card-text class="d-flex justify-center align-center">
              <v-row style="padding: 10px 40px">
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        class="text-left"
                        :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                      >
                        Masukkan password untuk mengakses halaman ini
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-model="loginForm.password"
                        type="password"
                        dense
                        style="height: 37px"
                        outlined
                        clearable
                        light
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <div
              class="d-flex justify-center align-center"
              style="padding: 0 50px"
            >
              <v-btn
                :loading="loading"
                class="white--text custom-btn"
                color="rgba(4, 123, 202, 0.979)"
                type="submit"
              >
                Submit
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'additionalcomponent',
  data: () => ({
    config: require('../../../../services/config.json')[env],
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      order_by: 'created_at',
      order_type: 'desc',
      company_id: null,
      payroll_component_id: null,
      department_id: null,
      start_date: null
    },
    totalData: 0,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Komponen',
        value: 'payroll_component_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Template',
        value: 'template_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Periode',
        value: 'period',
        align: 'left',
        sortable: false
      },
      {
        text: 'Action',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    dropdown: {
      company: [],
      department: []
    },
    loginForm: {
      email: '',
      password: ''
    },
    otp: null,
    request_id: null,
    timer: null,
    xInterval: null,
    showOtpInput: null,
    skeleton_loading: true
  }),

  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    // if (this.getLoginPayroll) {
    //   this.verifyAccessToken()
    // }
    this.getDataMenu()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters([
      'getUserProfile',
      'getLoginPayroll',
      'getPayrollAdditionalPageQuery'
    ])
  },
  watch: {
    // async 'paramAPI.company_id'() {
    //   await this.getDataFromApi(7)
    // }
    // async 'paramAPI.mode'() {
    //   await this.getDataFromApi()
    // },
    // async 'paramAPI.status_id'() {
    //   await this.getDataFromApi()
    // },
    // async 'paramAPI.start_date'() {
    //   await this.getDataFromApi()
    // }
  },
  methods: {
    ...mapMutations(['setLoginPayroll', 'setPayrollAdditionalPageQuery']),
    ...mapActions(['menu_access', 'getMenuId']),
    async verifyAccessToken() {
      this.loading = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            // this.getDataMenu()
            this.initDataForLayout()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.setLoginPayroll(null)
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async loginPayroll() {
      this.loading = true
      this.loginForm.email = this.getUserProfile.employee.email
      await axios
        .post(`${this.oAuthApi}user/login`, this.loginForm)
        .then((res) => {
          console.log('login page>>>', res)
          if (res.data.status_code === '00') {
            this.generateOTP()
            // // this.getDataMenu()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    setCountdown(dateTime) {
      var dateTimeNow = dateTime
      const self = this
      this.xInterval = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = dateTimeNow - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Output the result in an element with id="demo"
        self.timer =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(self.xInterval)
          self.timer = null
        }
        console.log(self.timer)
      }, 1000)
    },
    async generateOTP() {
      this.loading = true
      await axios
        .post(`${this.oAuthApi}otp/generate`, {
          application_id: 21,
          application_name: 'HRIS'
        })
        .then((res) => {
          console.log('generate otp>>>', res)
          if (res.data.status_code === '00') {
            this.request_id = res.data.request_id
            this.showOtpInput = setTimeout(() => {
              const dateTime = new Date().getTime() + 1 * 60000
              this.setCountdown(dateTime)
            }, 0)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async verifyOTP() {
      this.loading = true
      await axios
        .post(`${this.oAuthApi}otp/verify`, {
          request_id: this.request_id,
          otp: this.otp
        })
        .then((res) => {
          console.log('verify otp>>>', res)
          if (res.data.status_code === '00') {
            // this.access_token = res.data.data.x_access_token
            setTimeout(() => {
              this.loading = false
              // this.showMsgDialog('warning', 'Invalid OTP', false)
              this.showOtpInput = false
              this.setLoginPayroll(res.data.access_token)
              // this.getDataMenu()
              setTimeout(() => {
                this.initDataForLayout()
              }, 100)
              clearInterval(this.xInterval)
            }, 1000)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.loading = false
          }
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            // this.initDataForLayout()
            if (this.getLoginPayroll) {
              this.verifyAccessToken()
            }
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = 0
      } else {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      }

      if (this.getPayrollAdditionalPageQuery !== null) {
        this.paramAPI = this.getPayrollAdditionalPageQuery
      }
      setTimeout(async () => {
        this.getDataFromApi()
        this.getDropdownCompany()
        this.dropdownDepartment()
      }, 500)
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.hrsApi}employee/payroll_additional/delete/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    createAdditional() {
      setTimeout(() => {
        this.$router.push('/hr/payroll/additional/submission')
      }, 300)
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    async companyWatcher(v) {
      //   this.paramAPI.company_id = v
      this.resetSomeParam()
      this.dropdownDepartment()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async departmentWatcher(v) {
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}employee/payroll_additional/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.order_by}&order_type=${
        this.paramAPI.order_type
      }`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.payroll_component_id !== null) {
        url =
          url + `&payroll_component_id=${this.paramAPI.payroll_component_id}`
      }
      this.setPayrollAdditionalPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url, {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          })
          .then((res) => {
            console.log('list>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      if (this.permission.read_perm == 1) {
        setTimeout(() => {
          this.$router.push(`/hr/payroll/additional/detail/${pItem.id}`)
        }, 100)
      }
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.paramAPI.company_id !== null) {
        url = url + `?filter=[{"company_id":${this.paramAPI.company_id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('department>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    }
  }
}
</script>
<style lang="scss">
.custom-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
}
#additionalList {
  position: relative;
  .additional-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .additional-list-toolbar-1 {
      .additional-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .additional-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #additionalList {
    .additional-list-div {
      .additional-list-toolbar-1 {
        .additional-list-toolbar-1 {
          display: none;
        }
      }
      .additional-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
